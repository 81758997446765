import React from 'react'
import { Row, Col } from 'reactstrap'
import style from './gallery-strip.module.scss'

const GalleryStrip = props => {
  var images = []
  if (props.data) {
    images = props.data.map(img => {
      return (
        <Col {...props.col} key={img.id}>
          <img src={img.file.url} alt={img.title} className={props.padding} />
        </Col>
      )
    })
  }

  if ( !props.fluid) {
    var container = 'container'
  }


  return (
    <section className={`${style.GalleryStrip} gallery-strip` }>
      <div className={ container} >
        <Row className="no-gutters">{images}</Row>
      </div>
    </section>
  )
}

export default GalleryStrip
