import React from 'react'
import styles from './testimonial.module.scss'

class Testimonals extends React.Component {
  constructor({ data }) {
    super()
    this.data = data
  }

  render() {
    var testimonials = []
    if (this.data) {
      testimonials = this.data.map(testimonial => {
        return (
          <div key={testimonial.id} className={styles.testimonial}>
            {testimonial.content && testimonial.content.childMarkdownRemark.html ? (
              <div dangerouslySetInnerHTML={{ __html: testimonial.content.childMarkdownRemark.html }} />
            ) : (
              ''
            )}
            <p className={styles.position}>
              {testimonial.contact && <strong>{[0].name}</strong>}
              {testimonial.position ? ' ' + testimonial.position : ''}
            </p>
          </div>
        )
      })
    }

    return <>{testimonials}</>
  }
}

export default Testimonals
