import React from 'react'
import Swiper from 'react-id-swiper'
import style from './casestudy-carousel.module.scss'

const casestudyCarousel = props => {
  if (typeof window !== `undefined`) {
    var w = window.innerWidth
  }
  var effect = ''

  if (w >= 991) {
    effect = 'slide'
  }

  const params = {
    grabCursor: true,
    centeredSlides: true,
    loop: true,
    slidesPerView: 1,
    speed: 700,
    preloadImages: false,
    lazy: true,
    effect: effect,
    spaceBetween: 200,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    // Responsive breakpoints
    breakpoints: {
      // when window width is <= 640px
      991: {
        spaceBetween: 20,
      },
    },
  }

  var images = []
  if (props.data) {
    images = props.data.map(img => {
      return (
        <div {...props.col} key={img.id}>
          <img src={img.file.url} alt={img.title} className="img-fluid" />
          {img.description ? <span className={`${style.caption}`}>{img.description}</span> : ''}
        </div>
      )
    })
  }

  if (!props.fluid) {
    var container = 'container'
  }

  return (
    <section className={`${style.GalleryStrip} SingleCarousel `}>
      <div className={container}>
        <Swiper {...params}>{images}</Swiper>
      </div>
    </section>
  )
}

export default casestudyCarousel
